export function HeroTitle(): JSX.Element {
  return (
    <div className="bg-primary-600">
      <div className="flex flex-col items-start gap-[4px] self-stretch bg-primary-6-default px-5 pb-5 text-neutral-50 md:items-center  md:gap-[8px]">
        <h1 className="font-medium opacity-[0.8] text-base md:text-3xl">
          Encontre profissionais de saúde e
        </h1>
        <span className="font-inherit m-0 text-3xl font-bold leading-[40px] md:text-[64px] md:leading-[80px]">
          Marque consultas
        </span>
      </div>
    </div>
  );
}
