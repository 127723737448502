import briefcaseMedicalSolid from "../images/briefcase-medical-solid.svg";
import mapMarkedSolid from "../images/map-marked-solid.svg";
import userNurseSolid from "../images/user-nurse-solid.svg";
import { SeoList } from "./seo-list";

export function HeroSpecialties({
  specialties,
  cities,
  healthInsuraces,
}: {
  specialties: Record<string, string>;
  cities: Record<string, string>;
  healthInsuraces: Record<string, string>;
  mix: Record<string, string>;
}): JSX.Element {
  return (
    <section
      title="Encontrar profissionais por especialidade, plano de saúde e cidade"
      aria-label="Encontrar profissionais por especialidade, plano de saúde e cidade"
      className="flex flex-col gap-6 py-10 text-center text-[24px] md:gap-20 md:pt-24 "
    >
      <h2 className="text-neutral-700 font-semibold text-2xl md:text-3xl">
        Encontrar profissionais por
      </h2>
      <div>
        <div className="border-neutral-200 flex flex-col gap-10 border-b-[1px] border-none pb-10  md:flex-row md:justify-between">
          <SeoList
            icon={userNurseSolid}
            items={specialties}
            title="Especialidade"
          />
          <SeoList
            icon={briefcaseMedicalSolid}
            items={healthInsuraces}
            title="Plano de Saúde"
          />
          <SeoList icon={mapMarkedSolid} items={cities} title="Cidade" />
        </div>
        {/* <div className="pt-10">
          <SeoList
            icon={searchPlusSolid}
            items={mix}
            title="Outras buscas relevantes"
            horizontal
          />
        </div> */}
      </div>
    </section>
  );
}
